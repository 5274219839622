<template>
  <div id="elderly-care">
    <!-- 老人日常护理 -->
    <div class="con">
      <div class="lf">老人日常护理</div>
      <div class="re customScrollBar">
        <div class="empty" v-if="data.length == 0">
          <el-empty description="暂无护理内容">
            <el-button type="primary" @click="addCare">添加护理内容</el-button>
          </el-empty>
        </div>
        <div class="cc" v-else>
          <div class="item" v-for="(item, index) in data">
            <div class="l">
              <div class="h">
                <div class="hi">
                  <div class="title">护理内容</div>
                  <div class="val">
                    <el-input
                      v-model="item.name"
                      disabled
                      placeholder="请输入护理内容"
                    ></el-input>
                  </div>
                </div>
                <div class="hi">
                  <div class="title">护理周期</div>
                  <div class="val">
                    <el-select
                      v-model="item.cycleId"
                      disabled
                      placeholder="请选择护理周期"
                    >
                      <el-option
                        v-for="op in cycleList"
                        :key="op.id"
                        :label="op.value"
                        :value="op.id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="h">
                <div class="hi">
                  <div class="title">备注</div>
                  <div class="val">
                    <el-input
                      type="textarea"
                      :rows="2"
                      v-model="item.remark"
                      disabled
                      placeholder="请输入备注"
                    ></el-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="r">
              <el-button type="success" plain size="mini" @click="updateC(item)"
                ><i class="el-icon-edit" style="margin-right: 5px;"></i>编辑</el-button
              >
              <el-button
                type="danger"
                plain
                size="mini"
                @click="removeItem(item)"
                ><i class="el-icon-minus" style="margin-right: 5px;"></i>移除</el-button
              >
            </div>
          </div>
          <div class="but">
            <el-button type="primary" @click="addCare">添加护理内容</el-button>
          </div>
        </div>
      </div>
    </div>

    <diy-dialog
      v-if="dialogData.flag"
      :dialogData="dialogData.data"
      :title="dialogData.title"
      :width="dialogData.width"
      :buttonList="dialogData.buttonList"
      @showDialogData="showDialogData"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
    ></diy-dialog>
  </div>
</template>

<script>
import {
  getEverydayCareListByPage,
  addOrUpdateEverydayCareOne,
  delEverydayCareList
} from "@/api/elderly/everydayCare.js";
import { getDictionaryList } from "@/api/base/dictionary.js";
export default {
  data() {
    return {
      elderlyId: "",
      data: [],
      cycleList: [], // 护理周期

      dialogData: {
        tempData: {},
        flag: false,
        data: [],
        title: "添加护理周期",
        width: "40%",
        buttonList: [],
      },
    };
  },
  methods: {
    async getBaseData() {
      this.cycleList = await getDictionaryList({ key: "cycle" });
    },
    async initData() {
      let requestData = {
        elderlyId: this.elderlyId,
      };
      let resultData = await getEverydayCareListByPage(requestData);
      this.data = resultData.list;
    },
    addCare() {
      this.dialogData.title = "添加老人日常护理";
      this.openDialog({}, "makesureAdd");
      this.dialogData.flag = true;
    },
    updateC(item) {
      this.dialogData.title = "编辑老人日常护理";
      this.openDialog({}, "makesureUpdate");
      this.dialogData.tempData = item;
      this.dialogData.data = this.$total.json2array(this.dialogData.data,item)
      this.dialogData.flag = true;
    },
    openDialog(data, key) {
      this.dialogData.data = [
        {
          title: "护理内容",
          type: "input",
          value: "",
          key: "name",
          isNotNull: true,
        },
        {
          title: "护理周期",
          placeholder: "请选择护理周期",
          type: "select",
          value: "",
          key: "cycleId",
          isNotNull: true,
          options: this.cycleList,
        },
        {
          title: "备注",
          placeholder: "备注",
          type: "textarea",
          value: "",
          key: "remark",
          isNotNull: false,
        },
      ];
      if (data.id) {
        this.dialogData.data = this.$total.json2array(
          this.dialogData.data,
          data
        );
      }
      this.dialogData.buttonList = [
        { active: key, text: "确定" },
        { active: "cancel", text: "取消" },
      ];
    },
    async removeItem(data) {
      let requestData = {
        idList: [data.id],
      };
      await delEverydayCareList(requestData);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      await this.initData();
    },
    async save(requestData) {
      requestData = this.$total.array2json(this.dialogData.data, requestData);
      let mes = this.$total.judegArrayNull(this.dialogData.data, this.data);
      if (mes != "ok") {
        this.$message({
          message: mes + "不可为空!",
          type: "error",
        });
        return;
      }
      await addOrUpdateEverydayCareOne(requestData);
      this.$message({
        message: "操作成功!",
        type: "success",
      });
      await this.initData();
      this.colseDialog();
    },
    async dialogOper(key) {
      let requestData = {};
      switch (key) {
        case "makesureAdd":
          // 确定添加老人日常护理
          requestData = {
            id:"",
            elderlyId:this.elderlyId,
          }
          await this.save(requestData);
          break;
        case "makesureUpdate":
          // 确定添加老人日常护理
          requestData = {
            id:this.dialogData.tempData.id,
            elderlyId: this.elderlyId,
          };
          await this.save(requestData);
          break;
          case "cancel":
            this.colseDialog()
            break

        default:
          break;
      }
    },
    showDialogData(val) {
      this.dialogData.data = val;
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  async mounted() {
    this.elderlyId = this.$route.query.id ? Number.parseInt(this.$route.query.id) : "";
    await this.getBaseData();
    await this.initData();
  },
};
</script>

<style lang="less" scoped>
#elderly-care {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  > .con {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    padding: 10px 20px;
    box-sizing: border-box;
    > .lf {
      width: 10%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 600;
      padding-right: 20px;
      box-sizing: border-box;
    }
    > .re {
      width: 0;
      flex: 1;
      height: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      > .empty {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
      > .cc {
        width: 100%;
        display: flex;
        height: 100%;
        flex-direction: column;
        > .item {
          width: 60%;
          height: 140px;
          background-color: #f6f6f6;
          display: flex;
          border-radius: 5px;
          padding: 20px;
          box-sizing: border-box;
          margin-bottom: 20px;
          /deep/.el-select {
            width: 100%;
          }
          > .l {
            width: 0;
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            > .h {
              flex: 1;
              display: flex;
            }
          }
          > .r {
            width: auto;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-left: 20px;
          }
          .hi {
            flex: 1;
            display: flex;
            > .title {
              width: 100px;
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-right: 8px;
              font-size: 14px;
            }
            > .val {
              width: 0;
              flex: 1;
            }
          }
        }
        > .but {
          display: flex;
        }
      }
    }
  }
}
</style>