import request from "@/utils/request";
let baseUrl = "/nursinghomes/elderly/";

/**
 * @description: 分页查询老人信息
 * @return {*}
 */
export async function getElderlyListByPage(data) {
  return await request({
    url: baseUrl + "getElderlyListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 增加或修改老人信息
 * @return {*}
 */
export async function addOrUpdateElderlyOne(data) {
  return await request({
    url: baseUrl + "addOrUpdateElderlyOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取单条老人信息
 * @return {*}
 */
export async function getElderlyOneById(data) {
  return await request({
    url: baseUrl + "getElderlyOneById",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 查询老人健康监测统计
 * @return {*}
 */
export async function getSleepDeviceRecordECharts(data) {
  return await request({
    url: "/nursinghomes/sleepDeviceRecord/getSleepDeviceRecordECharts",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 删除老人信息
 * @return {*}
 */
export async function delElderlyOne(data) {
  return await request({
    url: baseUrl + "delElderlyOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}


/**
 * @description: 获取今日血压
 * @return {*}
 */
export async function getBloodToday(data) {
  return await request({
    url:  "/nursinghomes/blood/getBloodToday",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}

/**
 * @description: 获取七日记录
 * @return {*}
 */
export async function getBloodList(data) {
  return await request({
    url:  "/nursinghomes/blood/getBloodList",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}
/**
 * @description: 新增修改血压记录
 * @return {*}
 */
export async function addOrUpdateBloodOne(data) {
  return await request({
    url:  "/nursinghomes/blood/addOrUpdateBloodOne",
    headers: {
      isToken: true,
    },
    method: "post",
    data: data,
    timeout: 20000,
  });
}