export function getMenuData() {
  return [
    {
      path: "",
      fullPath: "",
      query: {},
      name: "员工",
      icon: "el-icon-folder-opened",
      meta: {
        title: "老人管理",
      },
      children: [
        {
          path: "/elderly",
          fullPath: "/elderly",
          query: {},
          name: "老人管理",
          meta: {
            title: "老人管理",
            activeMenu: "老人管理",
          },
        },
        {
          path: "/equipment",
          fullPath: "/equipment",
          query: {},
          name: "设备管理",
          meta: {
            title: "设备管理",
            activeMenu: "设备管理",
          },
        },
        {
          path: "/task",
          fullPath: "/task",
          query: {},
          name: "任务管理",
          meta: {
            title: "任务管理",
            activeMenu: "任务管理",
          },
        },
        {
          path: "/ItemCollection",
          fullPath: "/ItemCollection",
          query: {},
          name: "物品分类",
          meta: {
            title: "物品分类",
            activeMenu: "物品分类",
          },
        },
        {
          path: "/ItemRecord",
          fullPath: "/ItemRecord",
          query: {},
          name: "物品领取",
          meta: {
            title: "物品领取",
            activeMenu: "物品领取",
          },
        },
      ],
    },
    {
      path: "",
      fullPath: "",
      query: {},
      name: "商城",
      icon: "el-icon-sell",
      meta: {
        title: "商城管理",
      },
      children: [
        {
          path: "/sort",
          fullPath: "/sort",
          query: {},
          name: "分类管理",
          meta: {
            title: "分类管理",
            activeMenu: "分类管理",
          },
        },
        {
          path: "/goods",
          fullPath: "/goods",
          query: {},
          name: "商品管理",
          meta: {
            title: "商品管理",
            activeMenu: "商品管理",
          },
        },
        {
          path: "/order",
          fullPath: "/order",
          query: {},
          name: "订单管理",
          meta: {
            title: "订单管理",
            activeMenu: "订单管理",
          },
        },
        
        {
          path: "/address",
          fullPath: "/address",
          query: {},
          name: "地址配置",
          meta: {
            title: "地址配置",
            activeMenu: "地址配置",
          },
        },
      ],
    },
    {
      path: "",
      fullPath: "",
      query: {},
      name: "系统管理",
      icon: "el-icon-setting",
      meta: {
        title: "系统管理",
      },
      children: [
        {
          path: "/employ",
          fullPath: "/employ",
          query: {},
          name: "员工管理",
          meta: {
            title: "员工管理",
            activeMenu: "员工管理",
          },
        },
        {
          path: "/nurse",
          fullPath: "/nurse",
          query: {},
          name: "护理周期",
          meta: {
            title: "护理周期",
            activeMenu: "护理周期",
          },
        },
        {
          path: "/realData",
          fullPath: "/realData",
          query: {},
          name: "实时数据",
          meta: {
            title: "实时数据",
            activeMenu: "实时数据",
          },
        },
      
        {
          path: "/abnormalData",
          fullPath: "/abnormalData",
          query: {},
          name: "异常数据",
          meta: {
            title: "异常数据",
            activeMenu: "异常数据",
          },
        },
        {
          path: "/alarmData",
          fullPath: "/alarmData",
          query: {},
          name: "报警数据",
          meta: {
            title: "报警数据",
            activeMenu: "报警数据",
          },
        },
        // {
        //   path: "/questionnaire",
        //   fullPath: "/questionnaire",
        //   query: {},
        //   name: "问卷管理",
        //   meta: {
        //     title: "问卷管理",
        //     activeMenu: "问卷管理",
        //   },
        // },
        // {
        //   path: "/store",
        //   fullPath: "/store",
        //   query: {},
        //   name: "物品管理",
        //   meta: {
        //     title: "物品管理",
        //     activeMenu: "物品管理",
        //   },
        // },
        // {
        //     path: "/notice",
        //     fullPath: "/notice",
        //     query: {},
        //     name: "公告中心",
        //     meta: {
        //         title: "公告中心",
        //         activeMenu: '公告中心'
        //     },
        // },
      ],
    },
  ];
}
