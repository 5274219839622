<template>
  <div id="store">
    <!-- 物品管理 -->
    <div class="search">
      <top-search
        :searchData="searchData"
        :searchButton="searchButton"
        @showSearchData=""
        showSearchData
        @handleClick="handleSearchClick"
      ></top-search>
    </div>
    <div class="content">
      <div class="table">
        <!-- <diy-table
          :tableData="tableData"
          :tableMenu="tableMenu"
          :model="model"
          :width="'320'"
          @showTableData="showTableData"
          @handleClick="handleTableClick"
        ></diy-table> -->
        <el-row :gutter="12">
          <el-col
            class="custom-col"
            :span="6"
            v-for="(item, index) in items"
            :key="index"
          >
            <el-card class="box-card" shadow="hover">
              <el-row>
                <div class="card-title">
                  <!-- <span>{{ item.name }}</span> -->
                  <el-button
                    style="float: right; padding: 3px 0"
                    type="text"
                    @click="editThresholds(item)"
                    >修改阈值</el-button
                  >
                </div>
              </el-row>
              <el-row>
                <el-col :span="8" class="avatar-col">
                  <img class="imgAvatar" :src="item.image" alt="" />
                  <div class="avatarname">{{ item.elderlyName }}</div>
                </el-col>
                <el-col :span="16" class="info-col">
                  <div class="info">
                    <div class="detail">
                      <span>房间号: </span> <span>{{ item.bedName }}</span>
                    </div>
                    <div class="detail">
                      <span>呼吸率:</span>
                      <span>{{ item.respiratoryRate }} 次/分 ({{ item.respiratoryRateRange }})  </span>
                    </div>
                    <div class="detail">
                      <span>心率:</span> <span>{{ item.heartRate }} 次/分 ({{ item.heartRateRange }})</span>
                    </div>
                    <div class="detail">
                      <span>生命体征:</span>
                      <span> {{ item.lifeSignStatus }}</span>
                    </div>
                    <div class="detail">
                      <span>监测状态:</span
                      ><span :style="{ color: item.customColor }">
                        {{ item.status }}</span
                      >
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div> -->
      <el-dialog :visible.sync="isModalOpen" title="设置阈值范围" width="600px">
        <el-row>
          <el-col :span="12">
            <el-form>
              <el-form-item label="心跳最小值">
                <el-input-number v-model="minHeartRate" :min="0" />
              </el-form-item>
              <el-form-item label="心跳最大值">
                <el-input-number v-model="maxHeartRate" :min="minHeartRate" />
              </el-form-item>
              <el-form-item label="呼吸最小值">
                <el-input-number v-model="minBreathRate" :min="0" />
              </el-form-item>
              <el-form-item label="呼吸最大值">
                <el-input-number v-model="maxBreathRate" :min="minBreathRate" />
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12">
            <div class="threshold-display">
              <h3>当前阈值范围:</h3>
              <p>心跳: {{ minHeartRate }} - {{ maxHeartRate }}次/分钟</p>
              <p>呼吸: {{ minBreathRate }} - {{ maxBreathRate }}次/分钟</p>
            </div>
          </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeModal">取消</el-button>
          <el-button type="primary" @click="submitThresholds">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as TaskData from "./realData.js";
import {
  getNowElderlyRecord,
  editElderlyWarn,
} from "@/api/realData/realData.js";
export default {
  data() {
    return {
      searchData: TaskData.baseData().searchData,
      searchButton: TaskData.baseData().searchButton,
      tableData: [],
      tableMenu: TaskData.baseData().menuData,
      model: "realData",
      pageData: {
        currentPage: 1,
        total: 3000,
        pageSizes: [10, 20, 50, 100],
        pageSize: 50,
        page: 1, // 当前页码
      },

      isModalOpen: false,
      minHeartRate: 60, // 默认值
      maxHeartRate: 100, // 默认值
      minBreathRate: 12, // 默认值
      maxBreathRate: 23, // 默认值
      timer: null, // 定义定时器 ID
      elderlyId: "",
      items: [],
      loading: false,
      hasMore: true,
      page: 1,
      pageSize: 10,
    };
  },
  methods: {
    /**
     * 修改数据的函数
     * @param {string} key - 需要修改的对象属性
     * @param {any} value - 设置的新值
     */
    modifyItem(item) {
      if (item.status === "y") {
        item.status = "正常";
        item.customColor = "green"; // 字体颜色为绿色
      } else if (item.status === "w") {
        item.status = "异常";
        item.customColor = "red"; // 字体颜色为红色
      } else if (item.status === "n") {
        item.status = "报警";
        item.customColor = "red"; // 字体颜色为红色
      } else {
      }
    },
    editThresholds(val) {
      // 修改阈值
      this.elderlyId = val.elderlyId;
      this.isModalOpen = true;
    },

    closeModal() {
      this.isModalOpen = false;
    },
    async submitThresholds() {
      let requestData = {
        elderlyId: this.elderlyId,
        respiratoryRateRange: this.minBreathRate + "~" + this.maxBreathRate,
        heartRateRange: this.minHeartRate + "~" + this.maxHeartRate,
      };
      if (
        this.minHeartRate < this.maxHeartRate &&
        this.minBreathRate < this.maxBreathRate
      ) {
        await editElderlyWarn(requestData);

        this.$message.success(
          `心跳阈值已设置为: ${this.minHeartRate} - ${this.maxHeartRate}, 呼吸阈值已设置为: ${this.minBreathRate} - ${this.maxBreathRate}`
        );
        this.closeModal();
      } else {
        this.$message.error("请确保最小值小于最大值！");
      }
    },
    /**
     * 初始化界面数据
     */
    async initTable() {
      let requestData = {
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.currentPage,
        startTime: "",
        endTime: "",
      };
      requestData = this.$total.array2json(this.searchData, requestData);
      if (!(requestData.time == null || requestData.time.length == 0)) {
        requestData.startTime = requestData.time[0];
        requestData.endTime = requestData.time[1];
      }
      let resultData = await getNowElderlyRecord(requestData);
      this.pageData.total = resultData.total;
      resultData.list.forEach((element) => {
        this.$set(element, "tableButton", TaskData.baseData().tableButton);
      });

      this.items = resultData.list;

      // 修改状态及样式
      this.items.forEach((item) => {
        this.modifyItem(item);
      });
    },
    /**
     * 顶部搜索界面的按钮事件
     * @param key
     */
    async handleSearchClick(key) {
      switch (key) {
        case "search":
          await this.initTable();
          break;

        default:
          break;
      }
    },
    /**
     * 表格中按钮点击事件
     * @param val
     */
    async handleTableClick(val) {
      let row = val.row;
      let key = val.key;
      let requestData = {};
      let resultData = {};
      switch (key) {
        case "edit":
          //修改阈值
          this.elderlyId = row.elderlyId;
          this.isModalOpen = true;
          break;
        default:
          break;
      }
    },

    showTableData(val) {
      this.tableData = val;
    },
    showSearchData(val) {
      this.searchData = val;
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTable();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTable();
    },
    fetchData() {
      // 模拟接口请求
      this.timer = setInterval(() => {
        this.initTable();
      }, 5000);
    },
  },
  mounted() {
    this.initTable();
    this.fetchData();
    // this.loadMore(); // 初始化时加载第一批数据
  },
  beforeDestroy() {
    clearTimeout(this.timer); // 清除定时器
  },
};
</script>

<style lang="less" scoped>
#store {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;

  .dialog-footer {
    text-align: right;
  }

  .threshold-display {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #f9f9f9;
    > p {
      margin: 10px 0;
    }
  }

  > .search {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    min-height: 80px;
    height: auto;
    display: flex;
    margin-bottom: 20px;
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    > .table {
      width: 100%;
      flex: 1;
      height: 0;
      .imgAvatar {
        width: 100px;
        height: 100px;
      }
      .custom-col {
        margin-bottom: 12px;
      }
      .card-title {
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-bottom: 10px;
      }
      .info {
        .detail {
          margin-bottom: 10px;
          display: flex;

          span:first-child {
            width: 33%;
            display: flex;
            justify-content: flex-end;
            margin-right: 20px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .avatar-col {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .avatarname {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
      }
    }
    > .page {
      width: 100%;
      height: 50px;
    }
  }
}
</style>
