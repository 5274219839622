/**
 * 界面基础数据
 * @returns
 */
export function baseData() {
  return {
    searchData: [
      {
        title: "老人姓名",
        value: "",
        type: "input",
        key: "elderlyName",
      },
      // {
      //   title: "任务状态",
      //   value: "",
      //   type: "select",
      //   key: "status",
      //   options: [
      //     { id: "w", value: "待执行" },
      //     { id: "y", value: "已完成" },
      //     { id: "n", value: "未完成" },
      //   ],
      // },
      // {
      //   title: "任务类型",
      //   value: "",
      //   type: "select",
      //   key: "type",
      //   options: [
      //     { id: "daily", value: "日常任务" },
      //     { id: "system", value: "系统任务" },
      //     { id: "mall", value: "商城任务" },
      //   ],
      // },
      // {
      //   title: "任务时间",
      //   value: ["", ""],
      //   type: "time",
      //   key: "time",
      // },
    ],
    searchButton: [
      {
          name: "一键处理",
          icon: "",
          key: "doAll"
      }
    ],
    menuData: [
      {
        key: "dateTime",
        title: "记录时间",
        width: "200",
      },
      {
        key: "elderlyName",
        title: "老人姓名",
        width: "100",
      },
      {
        key: "bedName",
        title: "床位",
        width: "80",
      },

      {
        key: "heartRate",
        title: "心率",
        width: "80",
      },
      {
        key: "heartRateRange",
        title: "心率范围",
        width: "120",
      },

      {
        key: "respiratoryRate",
        title: "呼吸率",
        width: "80",
      },
      {
        key: "respiratoryRateRange",
        title: "呼吸率范围",
        width: "120",
      },
      {
        key: "sleepDeviceNumber",
        title: "睡眠仪编号",
        width: "120",
      },
      {
        key: "lifeSignStatus",
        title: "生命体征",
        width: "140",
      },
      {
        key: "bodyActivityStatus",
        title: "人体活动状态",
        width: "120",
      },
      {
        key: "isCheck",
        title: "是否处理",
        width: "120",
        isChangeColor: true,
        isChangeText: true,
      },
      {
        key: "status",
        title: "监测状态",
        width: "120",
        isChangeColor: true,
        isChangeText: true,
      },
      {
        key: "reason",
        title: "系统原因",
        width: "160",
      },
    ],
    tableButton: [
      { title: "处理异常数据", key: "editAbnormalData" },
    //   { title: "删除", key: "del" },
    ],
  };
}
