<template>
  <div id="main">
    <div class="left">
      <div class="logo">颐雅堂</div>
      <div class="menus">
        <el-menu :default-active="defaultActive" class="el-menu-vertical-demo">
          <template v-for="(menu, index) in menuList">
            <el-submenu :index="menu.name" :key="index" v-if="menu.children">
              <template slot="title">
                <i :class="menu.icon"></i>
                <span>{{ menu.name }}</span>
              </template>
              <el-menu-item
                :index="submenu.name"
                v-for="(submenu, ii) in menu.children"
                :key="ii"
                @click="addTag(submenu)"
                >{{ submenu.name }}</el-menu-item
              >
            </el-submenu>
            <el-menu-item v-else :index="menu.name" @click="addTag(menu)">
              <i :class="menu.icon"></i>
              <span>{{ menu.name }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="info">
          <span
            >欢迎
            <span style="color: #4e74ff">{{ uName }}</span>
            登录颐雅堂后台管理系统!</span
          >
          <el-dropdown>
            <img :src="require('../assets/icon.png')" alt="" />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="logout">退出</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="editPassword">修改密码</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="tips">
        <i class="iconfont icon-biaoqian"></i>
        <router-link
          v-for="tag in visitedViews"
          ref="tag"
          :key="tag.path"
          :class="isActive(tag) ? 'active' : ''"
          :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
          tag="span"
          class="tags-view-item"
          :style="activeStyle(tag)"
          @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''"
        >
          {{ tag.title }}
          <span
            v-if="!isAffix(tag)"
            class="el-icon-close"
            @click.prevent.stop="closeSelectedTag(tag)"
          />
        </router-link>
      </div>
      <div class="con">
        <keep-alive :include="cachedViews">
          <router-view />
        </keep-alive>
      </div>
      <div class="bottom-copyright div-flex-center">
        {{ this.$copyright }}
      </div>
    </div>
    <diy-dialog
      v-if="dialogData.flag"
      :dialogData="dialogData.data"
      :title="dialogData.title"
      :width="dialogData.width"
      :buttonList="dialogData.buttonList"
      @colseDialog="colseDialog"
      @dialogOper="dialogOper"
    ></diy-dialog>
  </div>
</template>

<script>
import * as MenuData from "./menu.js";
import {
  getExceptionElderlyRecordNum,
  editAdminPassword,
} from "@/api/com/com.js";

export default {
  data() {
    return {
      defaultActive: "",
      isCollapse: true,
      uName: "",
      menuList: MenuData.getMenuData(),
      apiResponse: null,
      dataPolling: null, // 存储定时器
      dialogData: {
        tempData: {},
        flag: false,
        data: [],
        title: "修改密码",
        width: "40%",
        buttonList: [],
      },
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
  },
  watch: {
    apiResponse(newValue) {
      if (newValue) {
        this.warning(); // 当返回的值包含错误时调用 warning 方法
      }
    },
  },
  methods: {
    addTag(tag) {
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push(tag.path);
    },
    //   判断是否为当前高亮显示的菜单
    isActive(route) {
      return route.path === this.$route.path;
    },
    // 高亮显示的样式
    activeStyle(tag) {
      if (!this.isActive(tag)) return {};
      this.defaultActive = tag.meta.activeMenu;
      this.activeTag = tag;
      return {
        "background-color": "#e8f3ff",
        "border-color": "#a3b1fd",
        color: "#4f67ff",
      };
    },
    // 跳转到最后一个菜单上
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === "Dashboard") {
          // to reload home page
          this.$router.replace({ path: "/redirect" + view.fullPath });
        } else {
          // this.$router.push("/");
        }
      }
    },
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
    editPassword() {
      this.dialogData.title = "修改密码";
      this.dialogData.flag = true;
      this.dialogData.data = [
        {
          title: "旧密码",
          key: "oldPassword",
          value: "",
          type: "input",
          isNotNull: true,
        },
        {
          title: "新密码",
          key: "newPassword",
          value: "",
          type: "input",
          isNotNull: true,
        },
        {
          title: "确认密码",
          key: "cerPassword",
          value: "",
          type: "input",
          isNotNull: true,
        },
      ];
      this.dialogData.buttonList = [
        { active: "sure", text: "确定" },
        { active: "cancel", text: "取消" },
      ];
    },
    async dialogOper(key) {
      let requestData = {};
      switch (key) {
        case "sure":
          // 确认添加员工信息
          let mes = this.$total.judegArrayNull(this.dialogData.data);
          if (mes != "ok") {
            this.$message({
              message: mes + "不可为空!",
              type: "error",
            });
            return;
          }
          requestData = this.$total.array2json(
            this.dialogData.data,
            requestData
          );

          await editAdminPassword(requestData);
          this.$message({
            message: "操作成功!",
            type: "success",
          });
          this.colseDialog();
          break;
        case "cancel":
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    // 判断是否显示关闭按钮
    isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    // 关闭选择标签页
    closeSelectedTag(view) {
      this.closePage(view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view);
        }
      });
    },

    // 关闭指定tab页签
    closePage(obj) {
      if (obj === undefined) {
        return this.$store
          .dispatch("tagsView/delView", router.currentRoute)
          .then(({ visitedViews }) => {
            const latestView = visitedViews.slice(-1)[0];
            if (latestView) {
              return router.push(latestView.fullPath);
            }
            return this.$router.push("/");
          });
      }
      return this.$store.dispatch("tagsView/delView", obj);
    },
    //监测是否有异常数据
    warning() {
      // 创建音频对象，设置音频文件路径
      const audio = new Audio(
        "https://www.yiyatang.com/nursinghomes/images/music/preview.mp3"
      );

      // 播放音频
      audio.play().catch((error) => {
        console.error("音频播放失败:", error);
        // this.$message({
        //   message: "音频已损坏,请联系管理员检查!",
        //   type: "warning",
        // });
      });

      this.$notify({
        title: "报警信息",
        message: `有 ${this.apiResponse} 个报警数据，请前往 [系统管理]->[报警数据] 查看处理！`,
        type: "warning",
        position: "bottom-right",
      });
    },
    fetchData() {
      // 模拟实时获取接口请求
    },
  },
  async mounted() {
    this.uName = localStorage.getItem("userName");
    // 启动定时请求
    this.dataPolling = setInterval(async () => {
      let resultData = await getExceptionElderlyRecordNum({});
      this.apiResponse = resultData;
      if (this.apiResponse > 0) {
        this.warning();
      }
      this.fetchData();
    }, 3000); // 每5秒请求一次
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.dataPolling);
  },
};
</script>

<style lang="less" scoped>
#main {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f5f5f6;
  > .left {
    width: 12%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    > .logo {
      width: 100%;
      height: 70px;
      background-color: #3752ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: xiaowei;
      color: #fff;
      font-size: 38px;
    }
    > .menus {
      width: 100%;
      height: 0;
      flex: 1;
      /deep/.el-menu {
        border: none;
      }
      /deep/.el-menu-item,
      /deep/.el-submenu__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
        // color: #fff;
        .iconfont {
          margin-right: 5px;
          //   color: #fff;
          font-size: 18px;
        }
      }
    }
  }
  > .right {
    width: 0;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-x: hidden;

    > .top {
      width: 100%;
      height: 70px;
      background-color: #fff;
      display: flex;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      justify-content: flex-end;
      > .info {
        width: auto;
        display: flex;
        align-items: center;
        > span {
          font-size: 13px;
        }
        img {
          width: 40px;
          height: 40px;
          margin: 0 10px;
        }
      }
    }
    > .tips {
      width: 100%;
      height: 60px;
      padding: 3px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;
      > i {
        color: #c1c1c1;
        margin-top: 3px;
      }
      .tags-view-item {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 26px;
        line-height: 26px;
        border: 1px solid #d8dce500;
        color: #495060;
        background: #fff;
        padding: 0 8px;
        font-size: 14px;
        margin-left: 5px;
        margin-top: 4px;
        border-radius: 3px;
        &:first-of-type {
          margin-left: 15px;
        }
        &:last-of-type {
          margin-right: 15px;
        }
        &.active {
          background-color: #42b983;
          color: #fff;
          border-color: #42b983;
          &::before {
            content: "";
            background: #fff;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: relative;
            margin-right: 2px;
          }
        }
        .el-icon-close {
          width: 16px;
          height: 16px;
          vertical-align: 2px;
          border-radius: 50%;
          text-align: center;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transform-origin: 100% 50%;
          &:before {
            transform: scale(0.6);
            display: inline-block;
            vertical-align: -3px;
          }
          &:hover {
            background-color: #b4bccc;
            color: #fff;
          }
        }
      }
    }
    > .con {
      width: 100%;
      height: 0;
      flex: 1;
      overflow: hidden;
    }
  }
}
</style>
